import { createSlice } from "@reduxjs/toolkit";
import { string } from "prop-types";
import moment from "moment";

const generalStatesReducer = createSlice({
  name: "general",
  initialState: {
    activeClubTab: 1,
    activeLeagueTab: 1,
    slotId: [],
    updateSlotId: [],
    priceListName: [
      "",
      moment(new Date()).valueOf(),
      moment(new Date()).valueOf(),
      false,
    ],
    priceListName: ["", moment(new Date()).valueOf(), moment(new Date()).valueOf(), false],
    activeTournamentTab: 3,
    unseenChats: 0,
    selectedGrpId: "",
    timezone: "UTC",
    leagueType: "",
    calendarStartTime: "",
    calendarEndTime: "",
    calendarInitalDate: moment().format() 
  },
  reducers: {
    changeActiveTab: (state, action) => {
      state.activeClubTab = action.payload;
    },
    setActiveLeagueTab: (state, action) => {
      state.activeLeagueTab = action.payload;
    },
    selectedSlotId: (state, action) => {
      console.log("Slots Array", action);
      state.slotId = action.payload;
    },
    updateSlotId: (state, action) => {
      console.log("Updated Slots Id Array", action);
      state.updateSlotId = action.payload;
    },
    priceListName: (state, action) => {
      console.log("PRice List Name ", action);
      state.priceListName = action.payload;
    },
    activeTournamentTab: (state, action) => {
      state.activeTournamentTab = action.payload;
    },
    unseenChats: (state, action) => {
      state.unseenChats = action.payload;
    },
    selectedGrpId: (state, action) => {
      state.selectedGrpId = action.payload;
    },
    changeTimeZone: (state, action) => {
      state.timezone = action.payload;
    },
    changeLeagueType: (state, action) => {
      state.leagueType = action.payload
    },
    changeCalendarStartTime: (state, action) => {
      state.calendarStartTime = action.payload;
    },
    changeCalendarEndTime: (state, action) => {
      state.calendarEndTime = action.payload;
    },
    changeCalendarInitialTime: (state, action) => {
      state.calendarInitalDate = moment(action.payload).format();
    },
  },
});
export const { changeActiveTab, setActiveLeagueTab, changeLeagueType } =
  generalStatesReducer.actions;
export const { selectedSlotId } = generalStatesReducer.actions;
export const { updateSlotId } = generalStatesReducer.actions;
export const { priceListName } = generalStatesReducer.actions;
export const { activeTournamentTab } = generalStatesReducer.actions;
export const { unseenChats } = generalStatesReducer.actions;
export const { selectedGrpId } = generalStatesReducer.actions;
export const { changeTimeZone } = generalStatesReducer.actions;
export const { changeCalendarStartTime, changeCalendarEndTime , changeCalendarInitialTime } = generalStatesReducer.actions;

export default generalStatesReducer.reducer;
