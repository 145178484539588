import { createSlice } from "@reduxjs/toolkit";

const bookingReducer = createSlice({
  name: "booking",
  initialState: {
    editSlot: false,
    changeDateTime: false,
    openBookingView: false,
    openCreateActivity: false,
    deleteActivityModal: false,
    openMatchView: false,
    openTrainingView: false,
    openTournamentView: false,
  },
  reducers: {
    editSlot: (state, action) => {
      state.editSlot = action.payload;
      state.openBookingView = false;
      state.openMatchView = false;
      state.openTrainingView = false;
      state.openTournamentView = false;
    },
    setChangeDateTime: (state, action) => {
      state.changeDateTime = action.payload;
    },
    openBookingView: (state, action) => {
      state.openBookingView = action.payload;
    },
    openCreateActivity: (state, action) => {
      state.openCreateActivity = action.payload;
    },
    deleteActivityModal: (state, action) => {
      state.deleteActivityModal = action.payload;
    },
    openMatchView: (state, action) => {
      state.openMatchView = action.payload;
    },
    openTrainingView: (state, action) => {
      state.openTrainingView = action.payload;
    },
    openTournamentView: (state, action) => {
      state.openTournamentView = action.payload;
    },
  },
});
export const {
  editSlot,
  openBookingView,
  openCreateActivity,
  deleteActivityModal,
  openMatchView,
  openTrainingView,
  openTournamentView,
  setChangeDateTime,
} = bookingReducer.actions;
export default bookingReducer.reducer;
