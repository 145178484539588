import axios, { AxiosError, AxiosRequestConfig } from "axios";

export const axiosBaseQuery = ({ baseUrl }: { baseUrl: string }) => async ({
    url,
    method,
    body,
    params,
  }: AxiosRequestConfig & {body?: any}) => {
    try {
      const token = localStorage.getItem("tokenud");
      const headers: { [key: string]: string } = {};
  
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
  
      const response = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers,
      });
  
      return { data: response.data };
    } catch (axiosError) {
      let err: any = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };