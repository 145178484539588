import React, { Component , useState } from "react";
import {
    Col,
    Row,
    Table,
    Modal,
    Collapse,
    Button,
    CardBody,
    Card,
} from "reactstrap";
import { baseUrl, changeSubscription, createSubscription, token } from "../Apis";
import "./plans.scss";
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardInput from './CardInput';
import axios from 'axios';
import {withRouter } from "react-router-dom";
import i18n from 'i18n-js';
import { priceKey } from "../../utils/levels";
function Plans(props) {

    const [selectedPlan, setselectedPlan] = useState(1);
    const [planId, setPlanId] = useState();
    const [plan, setPlan] = useState();
    const stripe = useStripe();
    const elements = useElements();

    const redirectToSubscribe = (planId , selectedId,plan) => {
        setselectedPlan(selectedId);
      
        
        console.log(planId)
        // props.history.push('/subscribe/'+planId);
        var body =  {
            userId:props.user._id,
                priceId:planId,
                plan:plan,
                email:localStorage.getItem('token')
        }
        changeSubscription(body).then((res)=>{
            console.log(res)
           
            props.onChange(res.status);
            

        })
    }   
 const updatePackage=()=>{

 }
    React.useEffect(() => {
        console.log(props.user)
        if(props.user)
    {
        if(props.user.subscriptionInfo)
        {
            if(props.user.subscriptionInfo.planId=='price_1KGB1bGOoC7dnIOcuQNLJ0ch')
            {
                setselectedPlan(1)
            }
           else if(props.user.subscriptionInfo.planId=='price_1KGB24GOoC7dnIOcj3nQVleL')
            {
                setselectedPlan(2)
            }
           else if(props.user.subscriptionInfo.planId=='price_1KGB2UGOoC7dnIOcEsWoIjuL')
            {
                setselectedPlan(3)
            }
        }
      
    }
        
      }, []);
 

        return (
            <div className="animated fadeIn plans">
  

                <Row className="mt-4 justify-content-center">

                    <Col md={3} className={'plansbox'}>
                        <div className={selectedPlan === 1 ? "planCardSelected" : "planCard"}>
                            {selectedPlan === 1 ?
                                <div className="yourPackage">
                                    <h6>
                                        <strong>
                                            {i18n.t("YourPackage")}
                                        </strong>
                                    </h6>
                                </div>
                                : null}
                            <h4 className="text-center mt-2"><strong> {i18n.t("BasicMate")} </strong></h4>
                           
                            <Row className="mt-4">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('clubProfile')}</label>
                                <p className="text-muted ml-2">{i18n.t('clubProfileDesc')}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('createHostGame')}</label>
                                <p className="text-muted ml-2">{i18n.t('createHostGameDesc')}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('createHostTournaments')}</label>
                                <p className="text-muted ml-2">{i18n.t('createHostTournamentsDec')}</p>
                            </Col>
                        </Row>

                            <Row className="endDiv" style={{position:'absolute',bottom:15,width:'94%'}}>
                                <Col md={12} className="text-center">
                                    {!priceKey ? 
                                    <>
                                    <label  className="mb-1"  style={{fontFamily:"NoirPro-SemiBold"}}> <strong> SEK 495 / {i18n.t('month')} </strong></label>
                                    <p className="text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr')}</p>
                                    <p className="mb-3 text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr1')}</p>
                                    </>
                                    :
                                    null}
                                    {selectedPlan === 1 ?
                                        <button  className="btn planBtnDisabled">{i18n.t("YourPackage")}</button>

                                        : <button onClick={() => { redirectToSubscribe('price_1KGB1bGOoC7dnIOcuQNLJ0ch', 1,"Basic") }} className="btn planBtn">{i18n.t('switchto')} {i18n.t("basic")}</button>

                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3} className={'plansbox'}>
                        {/* <div className="stickTab"><lable>Campaign until january 10</lable> </div> */}
                        <div className={selectedPlan === 2 ? "planCardSelected" : "planCard"}>
                            {selectedPlan === 2 ?
                                <div className="yourPackage">
                                    <h6><strong>{i18n.t('yourpackage')}</strong></h6>
                                </div>
                                : null}
                            <h4 className="text-center packageHeading"><strong> {i18n.t("MediumMate")} </strong></h4>
                            
                            <Row className="" style={{marginTop:5}}>
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('basic')}</label>
                                <p className="text-muted ml-2">{i18n.t('allIncludeBasic')}</p>
                            </Col>
                        </Row>
                        <Row className="" style={{marginTop:5}}>
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('clubStatistics')}</label>
                                <p className="text-muted ml-2">{i18n.t('clubStatisticDesc')}</p>
                            </Col>
                        </Row>
                        <Row className="" style={{marginTop:5}}>
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0" style={{textTransform:'capitalize'}}>{i18n.t('playerStatistics')}</label>
                                <p className="text-muted ml-2">{i18n.t('playerStatiticsDesc')}</p>
                            </Col>
                        </Row>

                       

                        <Row className="" style={{marginTop:15}}>
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label style={{marginBottom:0}}>{i18n.t('createtrainingdes')}</label>
                                <p className="text-muted ml-2">{i18n.t('trainingdes')}</p>
                            </Col>
                        </Row>
                           
                           
                            <Row className="endDiv" style={{position:'absolute',bottom:15,width:'94%'}}>
                                <Col md={12} className="text-center">
                                {!priceKey ? 
                                    <>
                                    <label className="mb-1" style={{fontFamily:"NoirPro-SemiBold"}}>SEK 795 / {i18n.t('month')}</label>
                                    <p className="text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr')}</p>
                                    <p className="text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr2')}</p>
                                    <p className="mb-3 text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr1')}</p>
                                    </>
                                    :
                                    null}
                                    {selectedPlan === 2 ?
                                        <button  className="btn planBtnDisabled">{i18n.t('yourpackage')}</button>

                                        : <button onClick={() => { redirectToSubscribe('price_1KGB24GOoC7dnIOcj3nQVleL', 2,"Medium") }} className="btn planBtn">{i18n.t('switchto')} {i18n.t("medium")} </button>

                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={3} className={'plansbox'}>
                        <div className={selectedPlan === 3 ? "planCardSelected" : "planCard"}>
                        {selectedPlan === 3 ?
                                <div className="yourPackage">
                                    <h6><strong>{i18n.t('yourpackage')}</strong></h6>
                                </div>
                                : null}
                            <h4 className="text-center packageHeading"><strong> {i18n.t("PremiumMate")} </strong></h4>
                          

                            <Row className="mt-4">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t("basic")} + {i18n.t("medium")}</label>
                                <p className="text-muted ml-2">{i18n.t('allIncludedBasicMedium')}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4" >
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{i18n.t('communication')}</label>
                                <p className="text-muted ml-2">{i18n.t('communicationDesc')}</p>
                            </Col>
                        </Row>
                        <Row className="mt-4" >
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label className="mb-0">{'Reward'}</label>
                                <p className="text-muted ml-2">{i18n.t('rewarddes')}</p>
                            </Col>
                        </Row>
                        {/* <Row className="mt-3">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label style={{marginBottom:0}}>{i18n.t('createtrainingdes')}</label>
                                <p className="text-muted ml-2">{i18n.t('trainingdes')}</p>
                            </Col>
                        </Row> */}

                            <Row className="endDiv" style={{position:'absolute',bottom:15,width:'94%'}}>
                                <Col md={12} className="text-center">
                                {!priceKey ? 
                                    <>
                                    <label className="mb-1" style={{fontFamily:"NoirPro-SemiBold"}}>SEK 995 / {i18n.t('month')}</label>
                                    <p className="text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr')}</p>
                                    <p className="text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr2')}</p>
                                    <p className="mb-3 text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr1')}</p>
                                    {/* <p className="mb-3 text-muted" style={{fontFamily:"NoirPro-Medium",fontSize:12,color:'grey'}}>{i18n.t('moneydescr2')}</p> */}
                                    </>
                                    : null }
                                    {/* <p>Try 2 weeks free, after that SEK 129 / month</p> */}
                                    {/* <br /> */}
                                    {selectedPlan === 3 ?
                                        <button  className="btn planBtnDisabled">{i18n.t('yourpackage')}</button>

                                        : <button onClick={() => { redirectToSubscribe('price_1KGB2UGOoC7dnIOcEsWoIjuL', 3,"Premium") }} className="btn planBtn">{i18n.t('switchto')} {i18n.t("premium")}</button>

                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

    
   
       
     
        <div >
   
        </div>
      
   
            </div>
        );
  
}

export default withRouter(Plans);