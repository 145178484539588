import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  chatList: [],
  currentChat: null,
};

const chatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setChatList: (state, action) => {
      state.chatList = [...state.chatList , ...action.payload];
      debugger;
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload;
    },
    clearChatHistory: (state) => {
      state.messages = [];
      state.currentChat = null;
    },
  },
});
export const { setMessages, setChatList, setCurrentChat, clearChatHistory } =
  chatReducer.actions;
export default chatReducer.reducer;
