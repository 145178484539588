import { useEffect, useState } from "react";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import { useGetChatsQuery } from "../../api/ChatAPI/chatEndpoints";
// import { setCurrentChat } from "../../store/reducers/chatReducer";
// import { useDispatch } from "react-redux";

let initializedFirebaseApp = firebase;
if (!firebase.apps.length) {
  initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCTllK1OKm-YRcEeKXEc2KNcPtmZFZrqIk",
    authDomain: "padel-mates-e1167.firebaseapp.com",
    databaseURL: "https://padel-mates-e1167.firebaseio.com",
    projectId: "padel-mates-e1167",
    storageBucket: "padel-mates-e1167.appspot.com",
    messagingSenderId: "839575022441",
    appId: "1:839575022441:web:cd1a27956908374d9b6bf8",
    measurementId: "G-PR4XT5MWMW",
  });
} else {
  initializedFirebaseApp = firebase;
}
let messaging;

if ("Notification" in window) {
  try {
    messaging = initializedFirebaseApp.messaging();
  } catch { }
  // Initialize Firebaseconst
} else {
  // messaging=false
  console.log(" not supported");
}
// @ts-expect-error
const NotificationProvider = (props) => {
  const clubId = localStorage.getItem("clubId");
  // const dispatch = useDispatch();
  const chatQuery = useGetChatsQuery(clubId, {
    skip: !clubId,
  });

  useEffect(() => {
    if (messaging) {
      messaging.onMessage((payload) => {
        console.log(
          "🚀 ~ file: NotificationProvider.tsx:37 ~ messaging.onMessage ~ payload:",
          payload
        );
        if (Notification.permission === "granted") {
          const title = payload?.notification?.title ?? "New Notification";
          const body = payload?.notification?.body ?? "Notification from PadelMates";
          const image = payload?.notification?.image ?? "";
          const notification = new Notification(title, {
            body,
            image,
            data: payload.data,
          });

          notification.onclick = () => {
            const data = notification?.data;
            if (data?.type === "chat") {
              chatQuery.refetch();
              props.history.push(`/chat/`);
            }
          };
        }
      });
    }
  }, []);
  return <>{props.children}</>;
};

export default withRouter(NotificationProvider);
