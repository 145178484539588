import { chatApi } from "./chatSlice";

export interface IMessage {
  id?: string;
  timeStamp: string; // date
  text: string;
  channelId: string;
  senderId: string;
}

export interface ILastMessageInfo {
  senderId: string;
  senderName: string;
  senderImage: string;
  timeStamp: string;
  text: string;
}

export interface IParticipant {
  id: string;
  name: string;
  image: string;
}

export interface IChat {
  _id: string;
  name: string;
  image: string;
  chatType: "private" | "group";
  participants: IParticipant[];
  lastMessageInfo?: ILastMessageInfo;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface IChatStoreState {
  messages: IMessage[];
  chatList: IChat[];
  currentChat: IChat | null;
}

export const addTagTypes = ["chats", "messages", "seen-status"];

const injectedRtkApi = chatApi
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getChats: build.query<IChat[], void>({
        query: (userId) => ({
          url: `/chat-portal/getChannelByUserId/${userId}`,
          method: "GET",
        }),
        providesTags: ["chats"],
      }),
      getChatsWithPagination: build.query<IChat[],  { userId: string; offset: number; limit: number; query: string }>({
        query: ({ userId, offset, limit, query }) => ({
          url: `/chat-portal/getChannelByUserId/${userId}?offset=${offset}&limit=${limit}&query=${query}`,
          method: "GET",
        }),
        providesTags: ["chats"],
      }),
      getMessageByChannelId: build.query<IMessage[], string>({
        query: (channelId) => ({
          url: `/chat-portal/message/channel/${channelId}`,
          method: "GET",
        }),
        providesTags: (result, _error, channelId) => [
          ...(result ? ["messages"] : []),
        ],
      }),
      sendMessage: build.mutation<IMessage, IMessage>({
        query: (message) => ({
          url: `/chat-portal/message`,
          method: "POST",
          body: message,
        }),
        invalidatesTags: (result, _error, message) => [
          ...(result ? ["messages"] : []),
          `chats`,
        ],
      }),
      createChannelForActivity: build.mutation<any, any>({
        query: (info) => ({
          url: `/chat-portal/group/${info.activityId}`,
          method: "POST",
          body: {
            gameType: info.gameType,
          },
        }),
        invalidatesTags: (result, _error, message) => [
          ...(result ? ["messages"] : []),
          `chats`,
        ],
      }),
      createPrivateChat: build.mutation<any, any>({
        query: (info) => ({
          url: `/chat-portal/private`,
          method: "POST",
          body: {
            user1: info.user1,
            user2: info.user2,
          },
        }),
        invalidatesTags: (result, _error, message) => [
          ...(result ? ["messages"] : []),
          `chats`,
        ],
      }),

      getChatSeenStatus: build.query<
        any,
        { userId: string; channelId: string }
      >({
        query: (info) => ({
          url: `/chat-portal/seen/?channelId=${info.channelId}&userId=${info.userId}`,
          method: "GET",
        }),
        providesTags: (result, _error, info) => [
          ...(result ? ["seen-status"] : []),
        ],
      }),
      getAllChatSeenStatus: build.query<any, string>({
        query: (userId) => ({
          url: `/chat-portal/seen/all/${userId}`,
          method: "GET",
        }),
        providesTags: (result, _error, userId) => [
          ...(result ? ["seen-status"] : []),
        ],
      }),
      setChatSeenStatus: build.mutation<
        any,
        { userId: string; channelId: string }
      >({
        query: (info) => ({
          url: `/chat-portal/seen`,
          method: "PUT",
          body: {
            channelId: info.channelId,
            userId: info.userId,
          },
        }),
        invalidatesTags: (result, _error, info) => [
          ...(result ? ["seen-status", "chats"] : []),
        ],
      }),
    }),
  });

export const {
  useGetChatsQuery,
  useGetChatsWithPaginationQuery,
  useGetMessageByChannelIdQuery,
  useSendMessageMutation,
  useCreateChannelForActivityMutation,
  useCreatePrivateChatMutation,
  useGetChatSeenStatusQuery,
  useSetChatSeenStatusMutation,
  useGetAllChatSeenStatusQuery,
} = injectedRtkApi;
