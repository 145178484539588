import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { newbaseUrl } from "../../views/Apis";

export const chatApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: newbaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("tokenud");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: () => ({}),
});
