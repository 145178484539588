import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";
import { newbaseUrl } from "../views/Apis";
// import { RootState } from "../store";

export const api = createApi({
  reducerPath: "baseApi",
  baseQuery: axiosBaseQuery({ baseUrl: newbaseUrl }),
  endpoints: () => ({}),
});
