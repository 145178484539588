import { createSlice } from "@reduxjs/toolkit";

const usersReducer = createSlice({
  name: "user",
  initialState: {
    user: {},
  },
  reducers: {
    addUser: (state , action) => {
        console.log("ACtion" , action);
        state.user = action.payload;
    }
  }
});
export const {addUser} = usersReducer.actions;
export default usersReducer.reducer;
