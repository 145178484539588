import { configureStore } from "@reduxjs/toolkit";
import { api } from "../api/apiSlice";
import { bookingapi } from "../api/BookingAPI/bookingSlice";
import { chatApi } from "../api/ChatAPI/chatSlice";
import { clubapi } from "../api/ClubAPI/clubSlice";
import bookingReducer from "./reducers/bookingReducer";
import generalStatesReducer from "./reducers/generalStatesReducer";
import userReducer from "./reducers/userReducer";
import chatReducer from "./reducers/chatReducer";
import { userApi } from "../api/UserAPI/userSlice";
import { leagueManagerAPI } from "../api/LeagueManagerAPI/leagueManagerSlice";
import { divisionManagerAPI } from "../api/DivisionManagerAPI/divisionManagerSlice";
import { matchAPI } from "../api/MatchAPI/matchAPISlice";
import { generateLeagueAPI } from "../api/GenerateLeagueAPI/generateLeagueSlice";
import { seasonManagerAPI } from "../api/GenerateScheduleAPI/scheduleManagerSlice";
import { teamManagerApi } from "../api/TeamAPI/teamManagerSlice";
import { statisticsApi } from "../api/StatisticsAPI/statisticsSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    booking: bookingReducer,
    general: generalStatesReducer,
    chat: chatReducer,
    [api.reducerPath]: api.reducer,
    [clubapi.reducerPath]: clubapi.reducer,
    [bookingapi.reducerPath]: bookingapi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [leagueManagerAPI.reducerPath]: leagueManagerAPI.reducer,
    [divisionManagerAPI.reducerPath]: divisionManagerAPI.reducer,
    [matchAPI.reducerPath]: matchAPI.reducer,
    [generateLeagueAPI.reducerPath]: generateLeagueAPI.reducer,
    [seasonManagerAPI.reducerPath]: seasonManagerAPI.reducer,
    [teamManagerApi.reducerPath]:teamManagerApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      api.middleware,
      clubapi.middleware,
      bookingapi.middleware,
      chatApi.middleware,
      userApi.middleware,
      leagueManagerAPI.middleware,
      divisionManagerAPI.middleware,
      matchAPI.middleware,
      generateLeagueAPI.middleware,
      seasonManagerAPI.middleware,
      teamManagerApi.middleware,
      statisticsApi.middleware,
    ]),
});
